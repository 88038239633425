import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/Layout"
import HeroHeader from "../../components/HeroHeader"
import HeroImage from "../../images/produce.jpeg"
import SEO from "../../components/SEO"
import ApplicationQuote from "../../components/ApplicationQuotes"
import ProducePackagingImages from "../../components/applicationImageComponents/producePackaging"

const PageBody = styled.main`
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 2.5em;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 900px;
  height: 600px;
  bottom: 50%;
  margin-bottom: -300px;
  left: 50%;
  margin-left: -450px;
  background-color: green;
  animation: fadeIn ease 0.5s;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 910px) {
    width: 750px;
    height: 500px;
    margin-bottom: -250px;
    margin-left: -375px;
  }

  @media (max-width: 775px) {
    width: 600px;
    height: 400px;
    margin-bottom: -200px;
    margin-left: -300px;
  }

  @media (max-width: 625px) {
    width: 450px;
    height: 300px;
    margin-bottom: -150px;
    margin-left: -225px;
  }

  @media (max-width: 475px) {
    width: 350px;
    height: 233px;
    margin-bottom: -116.5px;
    margin-left: -175px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    color: red;
    font-size: 2em;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  #machine-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  #application-btn {
    position: absolute;
    bottom: 30px;
    left: 145px;
  }
`

const StyledImageGalleryHeader = styled.h2`
  max-width: 1400px;
  margin: 1rem auto;
  border-bottom: 2px solid red;
`

const StyledImageGallery = styled.div`
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto;
  justify-content: space-between;
`

const Thumbnail = styled.div`
  position: relative;
  max-width: 330px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 1.4em;
    /* z-index: 3; */
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &:hover {
      color: red;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    color: white;
    animation: slideIn ease 0.5s;
    pointer-events: none;

    @keyframes slideIn {
      0% {
        margin-bottom: -150px;
      }
      100% {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-left: 15px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: auto;
      &:hover {
        color: red;
      }
    }
  }

  &:hover {
    .overlay {
      display: block;
    }
  }
`

const BackButton = styled.button`
  margin: 2rem;
  font-size: 1em;
  border: none;
  padding: 1rem;
  background-color: red;
  color: white;
  cursor: pointer;
`

const ProducePackagingPage = () => {
  const [modal, setModal] = useState({
    display: "none",
    name: "",
  })
  const [machineImage, setMachineImage] = useState({})
  const [machineLink, setMachineLink] = useState({})

  const [model, setModel] = useState({
    name: "",
  })

  function handleImageClick(e) {
    setModal({
      display: "block",
      name: e.target.dataset.model,
    })
    setMachineImage(e.target.src)
    setMachineLink(e.target.dataset.link)
    setModel({ name: e.target.dataset.model })
  }

  return (
    <>
      <SEO title="Produce Packaging" description="" />

      <Layout>
        <HeroHeader
          heroImage={HeroImage}
          // title="Warehouse Application"
          // subHeading="Keep Your Unique Warehouse Clean with Our Specialized Products."
          buttonTitle="See More"
          showButton="none"
        />
        <PageBody>
          <h1>Produce Packaging</h1>
          <p>
            Produce Packing plants, which serve as a collection center for
            fruits and vegetables prior to distribution can be operating at 110%
            one month and nearly closed down entirely another. Our battery
            scrubbers' fully accessible recovery tank, allows for unsurpassed
            sanitation and disinfecting of the recovery (dirty) tank. The
            Factory Cat scrubbers are used to recover the fluid spills from the
            many Wash Baths that are required, and also clean up the soil
            tracked around by the material handling vehicles. The facilities
            develop strategies for Sorting/Trimming, Washing, Drying, Waxing,
            Curing, Chemical Treatments, Grading, Packaging, Pre-cooling,
            Storage and Transportation, with each creating unique requirements
            on the floor scrubber or floor sweepers. The larger packing plants
            will use rider scrubbers to tackle the floor scrubbing task in
            minimum time, and often employ smaller walk behind scrubbers for the
            congested areas in the building.
          </p>
          <h2>Simple Machines for Anyone to Use With Reliable Results:</h2>
          <ul>
            <li>
              Multiple operators are common, so simple controls on the scrubber
              are necessary, as is the ability to quickly clean up after use.
            </li>
            <li>
              Factory Cat sweepers and scrubbers come with automatic chargers,
              making recharge easy and reliable.
            </li>
            <li>
              The scrubber/sweeper combination machines are popular, as they can
              sweep and scrub in a single pass, reducing clogged vacuum hoses
              and streaks on the floor.
            </li>
          </ul>

          <ApplicationQuote quote="The generally humid and moist environment typically present in the produce facilities makes cleaning difficult and hard on scrubbers. FactoryCat machines with Zero3 aqueous ozone are very effective at cleaning your facilities. -JC" />
          <ApplicationQuote quote="Stainless Steel, Cylindrical deck & hopper allow for sweeping food & pallet debris off of wet floors.  Easy clean out for care of recovery tanks.  Zero3 to prevent bad odors. -KB" />
          <ApplicationQuote quote="Produce is very susceptible to Carbon Dioxide emitted by many of the competitive machines that require an engine to match the productivity FactoryCat delivers safely in a battery-operated floor scrubber. -JM" />
        </PageBody>

        <ProducePackagingImages />
        <Link to="/applications">
          <BackButton>All Applications</BackButton>
        </Link>
      </Layout>
    </>
  )
}

export default ProducePackagingPage
